import React, {useState,useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route,Outlet } from 'react-router-dom'
import Header from './Header';
import Menu from './Menu';
import Dashboard from './Dashboard';
import Footer from './Footer';
import About from './component/About';
import Home from './component/Home';
import Career from './component/Career';
import Contact from './component/Contact';
import Blog from './component/Blog';
import ContactForm from './component/Forme/ContactForm';
import { ContactDetail } from './component/Forme/ContactDetail';
import Resume from './component/Forme/Resume';
import Course from './component/Course';
import AddCourse from './component/AddCourse';
import { Job } from './component/uses/Job';
import AddJob from './component/uses/AddJob';
import ViewContactForm from './component/Forme/ViewContactForm';
import Testimonial from './component/Testimonial';
import AddUpdateTestimonial from './component/uses/AddUpdateTestimonial';
import Faq from './component/Faq';
import AddFaq from './component/uses/AddFaq';
import StudentVideo from './component/StudentVideo';
import AddStudentVideo from './component/uses/AddStudentVideo';
import Login from './component/login';
import FeAbout from './pages/About';
import FeHeader from './pages/Header';
import FeFooter from './pages/Footer'
import FeBlog from './pages/Blog';
import FeCareer from './pages/Career';
import FeContact from './pages/Contact';
import FeCourse from './pages/Course';
import GalleryF from './pages/GellaryF';
import FeIndex from './pages/Index';
import FeSingleBlogPage from './pages/SingleBlogPage';
import AddBlog from './component/uses/addBlog';
import RegisterForm from './component/Forme/RegisterForm';
import Gellary from './component/Forme/Gellary';
import AddGellary from './component/Forme/AddGellary';
import Student from './component/Student';
import AddStudent from './component/uses/addStudent';
import Member from './component/Member';
import AddMember from './component/Forme/AddMember';
import NotFoundPage from './pages/NotFoundPage';
import Office from './component/Office';
import AddOffice from './component/uses/AddOffice';
import PartnerForm from './component/Forme/PartnerForm';
import PartnerFormView from './component/Forme/PartnerFormView';
import NewsLetter from './component/NewsLetter';
import SpokenEnglish from './pages/SpokenEnglish';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  // Example function to set login status

  // useEffect(() => {
  //   // Check for token in localStorage
  //   const token = localStorage.getItem('token');
  //   if (token) {
  //     setIsLoggedIn(true);
  //   }
  // }, []);
  useEffect(() => {
    // Check if token is expired when the component mounts
    const token = localStorage.getItem('token');
    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = parseJwt(token);
      const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
      if (decodedToken.exp < currentTime) {
        // Token expired, redirect to login page
        setIsLoggedIn(false);
      } else {
        // Token valid, redirect to dashboard
        setIsLoggedIn(true);
      }
    }
  }, [isLoggedIn]);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };


  // Example function to set logout status
  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <div className="wrapper">
      <Router>        
        <Routes>
        <Route path='/*' element={<HomeLayout />}>
          <Route path='' element={<FeIndex/>} />
          <Route path='about' element={<FeAbout/>} />
          <Route path='contact' element={<FeContact />} />
          <Route path='career' element={<FeCareer />} />
          <Route path='spoken-english' element={<SpokenEnglish />} />
          <Route path='blog' element={<FeBlog />} />
          <Route path='gallary' element={<GalleryF/>} />
          <Route path='blog-description/:id' element={<FeSingleBlogPage />} />
          <Route path='Course/:courseId' element={<FeCourse />} />
          <Route path='*' element={<NotFoundPage />} />
          </Route>
          <Route path='login' element={<Login handleLogin={handleLogin} />} />
          {isLoggedIn? (
           <Route path='/admin/*' element={<AdminLayout />}>
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='about' element={<About />} />
              <Route path='home' element={<Home />} />
              <Route path='career' element={<Career />} />
              <Route path='contact' element={<Contact />} />
              <Route path='blog' element={<Blog />} />
              <Route path='contact-form' element={<ContactForm />} />
              <Route path='partner' element={<PartnerForm />} />
              <Route path='contact-detail' element={<ContactDetail />} />
              <Route path='resume' element={<Resume />} />
              <Route path='courses' element={<Course />} />
              <Route path='addcourse' element={<AddCourse />} />
              <Route path='editcourse/:courseId' element={<AddCourse />} />
              <Route path='all-job' element={<Job />} />
              <Route path="add-job" element={<AddJob />} />
              <Route path="edit-job/:jobId" element={<AddJob />} />
              <Route path='view-contact-form/:contactId' element={<ViewContactForm />} />
              <Route path='view-partner-form/:partnerId' element={<PartnerFormView />} />
              <Route path='testimonial' element={<Testimonial />} />
              <Route path="add-testimonial" element={<AddUpdateTestimonial />} />
              <Route path="edit-testimonial/:tId" element={<AddUpdateTestimonial />} />
              <Route path='office' element={<Office />} />
              <Route path="add-office" element={<AddOffice />} />
              <Route path="edit-office/:oId" element={<AddOffice />} />
              <Route path="faq" element={<Faq />} />
              <Route path="add-faq" element={<AddFaq />} />
              <Route path="studentvideo" element={<StudentVideo />} />
              <Route path="gellary" element={<Gellary/>} />
              <Route path="add-gellary" element={<AddGellary/>} />
              <Route path="add-Media" element={<AddStudentVideo />} />
              <Route path="add-blog" element={<AddBlog />} />
              <Route path="edit-blog/:bId" element={<AddBlog />} />
              <Route path="register-form" element={<RegisterForm />} />
              <Route path="students" element={<Student/>} />
              <Route path="member" element={<Member/>} />
              <Route path="newsletter" element={<NewsLetter/>} />
              <Route path="add-students" element={<AddStudent/>} />
              <Route path="edit-students/:sId" element={<AddStudent/>} />
              <Route path="add-member" element={<AddMember/>} />
              <Route path="edit-member/:memberId" element={<AddMember/>} />
              </Route>
          ):(
            <Route path='/admin/*' element={<Login handleLogin={handleLogin} />} /> // Redirect all routes to login page if not logged in
          )}
        </Routes>
        
      </Router>
    </div>
  );

  function HomeLayout() {
    return (
      <div className="wrapper">
        <FeHeader />
       
          <Outlet />
      
        <FeFooter />
      </div>
    );
  }
  
  function AdminLayout() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />
       
          <Outlet />
      
        <Footer />
      </div>
    );
  }

  function parseJwt(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }
}

export default App;
