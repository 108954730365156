import React, { useState, useEffect } from 'react';
import PageServices from '../services/PageServices';
import useAsync from '../hooks/useAsync';
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import Menu from '../Menu';
function Course() {

  const { data, loading, error, run } = useAsync(PageServices.getBlogData);

  const [form, setform] = useState([]);
  console.log(data)
  useEffect(() => {
    if (data?.data?.blog) {
      setform(data.data.blog || []); // Assuming data.data.form is an array of contact objects
    }
  }, [data]);

  const handleDelete = async (e,id) => {
    e.preventDefault();
    const confirmed = window.confirm('Are you sure you want to delete this blog?');

  if (!confirmed) {
    return; // If not confirmed, do nothing
  }
    try {
      const response = await PageServices.deleteBlogDataById(id);

      if(response.status === 'success'){
        alert("blog deleted successfully")
        run();
                
      }else{
        alert('something went wrong')
      }
    } catch (error) {
      // Handle error if the request fails
      console.error('Error fetching data:', error);
    }
  };
  

  return (
    

    <div className="content-wrapper">
   
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>Blogs</h1>
          </div>
          <div className="col-sm-6">
          <div className="text-right">
                  
                  <Link to="/admin/add-blog" className="btn btn-sm btn-primary">
                  <i className="fa fa-Page" />
                  Add blog
                </Link>
                </div>
                </div>
        </div>
      </div>
    </section>
  
    <section className="content">

    <div className="card-body pb-0 raw">
        <div className="row d-flex align-items-stretch">
     {form.map((course)=>(
          <div className='col-12 col-sm-4 col-md-4'>
          <div className="card"  key={course._id}>
            <div className='blog-card-img'>
          <img className="card-img-top mt-2" src={`${process.env.REACT_APP_URL}/uploads/${course.image}`} alt="Card image cap" />
          </div>
          <div className="card-body">
            <h5 className="card-title">{course.blogTitle}</h5>
          </div>
          <div className="card-footer">
                <div className="text-right">
                  
                <Link to={`/admin/edit-blog/${course.id}`} className="btn btn-sm btn-primary">
                Update Blog
                </Link>
                <button
                  onClick={(e) => handleDelete(e,course.id)}
                  className="btn btn-danger btn-sm ms-2 " 
                >
                  Delete
                </button>
                </div>
              </div>
        </div>
        </div>
      ))}
        </div>
      </div>
      

    </section>
    
  </div>
  
  )
}

export default Course