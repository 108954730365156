import {React, useState,useEffect} from 'react'
import Header from './Header'
import { Footer } from './Footer'
import useAsync from '../hooks/useAsync';
import PageServices from '../services/PageServices';

import DocumentMeta from 'react-document-meta';

function GellaryF() {

  const { data, loading, error, run } = useAsync(PageServices.getPhoto);

  const [form, setform] = useState([]);
  
  useEffect(() => {
    if (data?.data?.media) {
      setform(data.data.media || []); // Assuming data.data.form is an array of contact objects
    }
  }, [data]);
  const meta = {
    title: 'Gallery',
    description: 'Learn about our company.',
    
  };
  return (
    <>
     <DocumentMeta {...meta} />
        <div className="banner-sec new-banner-sec gallery-banner">
            <div className="container">
                <div className="banner-content text-center">
                    <h1 className="banner-heading ">Gallery</h1>
                </div>
            </div>
        </div>
        <section className="gallery-section py-70">
        <div className="container">
          <h2 className="heading text-center d-block">Employee Engagement Activities <br />at Gateway Abroad!</h2>
          <div className="gallery-section-inner pt-5">
            {form.map((g)=>(
              <div className="card-image">
                <a href={`${process.env.REACT_APP_URL}/uploads/${g.mediaLink}`} data-fancybox="gallery" data-caption="Caption Images 1">
                  <img src={`${process.env.REACT_APP_URL}/uploads/${g.mediaLink}`} alt="Image Gallery" />
                </a>
              </div>
              
              ))}
            
          </div>

          <div className='raw'  style={{ textAlign: 'center' }}>

            <button className="site-btn" >Load More</button>
            
            </div>
            
        </div>
      </section>

    </>
  )
}

export default GellaryF