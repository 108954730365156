import {React,useEffect,useState} from 'react'
import Header from './Header'
import { Footer } from './Footer'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import PageServices from '../services/PageServices';
import useAsync from '../hooks/useAsync';
import DocumentMeta from 'react-document-meta';
import { useNavigate } from 'react-router-dom';
function SingleBlogPage() {
  const navigate = useNavigate();
  const { data, loading, error, run } = useAsync(PageServices.getBlogData);
   const[blogData,setblogData] = useState([]);
  const { id } = useParams();
  const [image, setImage] = useState('');
  const [title, setTitel] = useState('');
  const [description, setDescription] = useState('');
  const [htmlSnipit, setHtmlSnipti] = useState('');
  const [category, setCategory] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  useEffect(() => {
    if (data?.data?.blog) {
      setblogData(data.data.blog||[])// Replace 'description' with the actual key from your API response
    }
    
    const fetchData = async () => {
      try {
        const response = await PageServices.getBlogDataById(id);
        if (response.status === 'success') {
          setTitel(response.data.blog?.blogTitle || '');
          setImage(response.data.blog?.image || '');
          setDescription(response.data.blog?.blogDescription || '');
          setHtmlSnipti(response.data.blog?.htmlSnipit || '');
          setCategory(response.data.blog?.category || '');
          setCreatedBy(response.data.page?.createdBy || '');
          setCreatedAt(response.data.page?.createdAt || '');
        } else {
          console.log('something went wrong');
        }
      } catch (error) {
        // Handle error if the request fails
        console.error('Error fetching data:', error);
        navigate(`/blog`);
      }
    };

    // Call the asynchronous function
    fetchData();
  
}, [id,data]);
const formatDate = date => {
  const options = { month: 'short', day: '2-digit', year: 'numeric' };
  return new Date(date).toLocaleDateString('en-US', options);
};

const meta = {
  title: title,
  description: 'Not Found page',
  // Add more meta tags as needed
};

  return (
    <div>
      <DocumentMeta {...meta} />
          <div>
        {/* ======== hero section start ===== */}
        <section>
          <div className="banner-sec new-banner-sec single-blog-banner">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="banner-heading">{title}</h1>
                <div className="blog-details mt-3">
                  <ul className="d-flex list-unstyled justify-content-center">
                    {/* <li className="blog-admin"><span><i className="fa fa-user-o" /></span>{createdBy}</li>
                    <li className="blog-date"><span><i className="fa fa-calendar-o" /></span>{formatDate(createdAt)?formatDate(createdAt):"DD/MM/YYYY"}</li> */}
                    <li className="blog-read-time"><span><i className="fa fa-clock-o" /></span>5 Minute Read</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== hero section end ===== */}
        <section className="blog-content-section py-60">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="blog-content-section-left">
                <div dangerouslySetInnerHTML={{ __html: description }} />
                  <div className="share-blog-section">
                    <ul className="list-unstyled">
                      <li><Link to="#" className="share-fb"><span><i className="fa fa-facebook" /></span>Facebook</Link></li>
                      <li><Link to="#" className="share-twi"><span><i className="fa fa-twitter" /></span>Twitter</Link></li>
                      <li><Link to="#" className="share-link"><span><i className="fa fa-linkedin" /></span>Linkedin</Link></li>
                      <li><span><i className="fa fa-share-alt" /></span><span>0<br />Share</span></li>
                    </ul>
                  </div>
                  {/* <div className="post-section">
                    <div className="pre-post-section post-section-inner">
                      <small>Previous Post</small>
                      <Link to="#">Check GMAT Exam Pattern (Section-Wise): Syllabus &amp; Scoring</Link>
                    </div>
                    <div className="next-post-section post-section-inner">
                      <small>Next Post</small>
                      <Link to="#">GMAT Focus Exam fees in India: Registration and Rescheduling</Link>
                    </div>
                  </div> */}
                  <div className="post-comment-section">
                    <h4>Leave a Reply</h4>
                    <p>Your email address will not be published.</p>
                    <form className="post-comment-form">
                      <div className="row gy-3">
                        <div className="col-md-6">
                          <div className="input-field">
                            <input type="text" name="name" placeholder="Name" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <input type="email" name="email" placeholder="Email" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="input-field">
                            <textarea placeholder="Comment" className="form-control" rows={3} defaultValue={""} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="input-field">
                            <button type="submit" className="post-cmnt-btn site-btn">Post Comment </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blog-content-section-right ps-4">
                  <div className="blog-search-box mb-50">
                    <h5>Search</h5>
                    <div className="blog-search-box-inner">
                      <input type="search" name="search" className="form-control" />
                      <button><i className="fa fa-search" /></button>
                    </div>
                  </div>
                  <div className="latest-post-section mb-50">
                    <h5>Latest Post</h5>
                    <div className="latest-post-section-inner">
                    {blogData.slice(0,2).map((blog,i)=>(
                      <div className="latest-post-card">
                        <div className="latest-post-card-img">
                          <img src="assets/img/latest-post-1.svg" />
                        </div>
                        <div className="latest-post-card-body">
                          <h6><Link to={`/blog-description/${blog._id}`}>{blog.title}</Link></h6>
                        </div>
                      </div>
                      ))}
                      
                    </div>
                  </div>
                  <div className="mb-50 categories-sec">
                    <h5>Categories</h5>
                    <div className="categories-sec-inner">
                      <ul className="list-unstyled">
                        <li><Link to={`/blog`}>GMAT</Link></li>
                        <li><Link to={`/blog`}>TOEFL</Link></li>
                        <li><Link to={`/blog`}>IELTS</Link></li>
                        <li><Link to={`/blog`}>GRE</Link></li>
                        <li><Link to={`/blog`}>PTE</Link></li>
                        <li><Link to={`/blog`}>SAT</Link></li>
                        <li><Link to={`/blog`}>SPOKEN ENGLISH</Link></li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== become partner section start ===== */}
        <section className="app-banner-section">
          <div className="container">
            <div className="app-banner-section-inner app-banner-section-inner-2">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="app-banner-content-left">
                    <h2 className="mb-3">Have a question about GMAT?</h2>
                    <p className="mb-4">Want some help figuring out what kind of prep service is right for you?</p>
                    <Link className="site-btn" to="/contact">Help &amp; Support</Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="app-banner-content-right text-center">
                    <img src="assets/img/help-support-img.svg" alt="partner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== become partner section end ===== */}
        {/* ======== footer section end ===== */}
       
      </div>
    </div>
  )
}

export default SingleBlogPage