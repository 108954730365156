import React, { Component } from 'react'

export default class Header extends Component {
 
  handleLogout = () => {
    // Remove token from local storage
    localStorage.removeItem('token');

    // Redirect to login page or perform any other actions after logout
    // Example: Redirect to the login page
    window.location.href = '/login';
  };

    render() {
        return (
           <div>
  <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    
    <ul className="navbar-nav ml-auto">
     
      <li className="nav-item">
        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#">
          <i className="fa fa-sign-out" style={{fontSize: '38px'}} onClick={this.handleLogout} />
        </a>
      </li>
    </ul>
  </nav>
</div>

        )
    }
}
