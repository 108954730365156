import {React ,useEffect ,useState} from 'react'
import { Link } from 'react-router-dom'
import PageServices from '../services/PageServices'
import useAsync from '../hooks/useAsync';

export const Footer = () => {
  const { data, loading, error, run } = useAsync(PageServices.getSettingData);
  const { data: course, loading: jobFormLoading, error: jobFormError, run: runJobForm } = useAsync(PageServices.getCourse);
  const [CourseData,setCourseData] = useState([])

  const [contactData,setContactData] = useState([]);
  const [name,setName] = useState([]);
const [email, setEmail] = useState('');
const [city, setCity] = useState('');
const [mobile, setMobile] = useState('');
const [message, setMessage] = useState('');
const [newsEmail, setNewsEmail] = useState('');
  useEffect(() => {
    // Check if data is available and update form fields
    if(course?.data?.page){
      setCourseData(course.data.page)
    }

    if (data?.data?.setting) {
      setContactData(data.data.setting);
    }
  }, [data,course]);

  const handleUpdate = async (e) => {

    e.preventDefault();
    
      try {
        if (!name || !email || !mobile || !city || !message) {
          alert('All fields are required');
          return;
        }
        // Make an API call to update the data
        const createJob = await PageServices.createForme({
          name:name,
          email:email,
          mobileNo:mobile,
          city:city,
          message:message,
          type:'contact'
        });
  
        if(createJob.status === 'success'){
          
          alert('Your request is submited');
          setName('');
          setEmail('');
          setMobile('');
          setCity('');
          setMessage('');
                  
        }else{
          alert('Something went wrong');
        }
  
      } catch (error) {
        console.error("something is wrong");
        // Handle the error, e.g., show a message to the user
      }
    
  };

  const handleUpdate2 = async (e) => {

    e.preventDefault();
        
      try {
        if (!newsEmail ) {
          alert('All fields are required');
          return;
        }
        // Make an API call to update the data
        const createJob = await PageServices.addEmail({
          email:newsEmail,
          Subscribed:'Yes'
        });
  
        if(createJob.status === 'success'){
          
          alert('Your request is submited');
          setNewsEmail('');            
        }else{
          alert('Something went wrong');
        }
  
      } catch (error) {
        console.error("something is wrong");
        // Handle the error, e.g., show a message to the user
      }
    
  };

  //console.log(data)
  return (
  <>
  <footer>
      <div className="footer-inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="footer-left">
                <div className="logo-sec">
                  <Link to="/">
                    <img src="assets/img//ga-logo.svg" alt="logo" />
                  </Link>
                </div>
                <div className="footer-desc">
                  <p>Gateway Abroad (an educational consultant) has been counselling and assisting students to study in the UK, IRELAND, AUSTRALIA, the USA, CANADA, NEW ZEALAND, SINGAPORE, and other countries for 15+ years.</p>
                </div>
                <div className="social-media-sec">
                  <h4 className="footer-title">Follow us</h4>
                  <ul className="d-flex list-unstyled">
                    <li><Link to={contactData.facebook} target="_blank"><i className="fa fa-facebook" /></Link></li>
                    <li><Link to={contactData.tweeter} target="_blank"><i className="fa fa-quora" /></Link></li>
                    <li><Link to={contactData.googlePlus} target="_blank"><i className="fa fa-google-plus" /></Link></li>
                    <li><Link to={contactData.pintrest} target="_blank"><i className="fa fa-pinterest" /></Link></li>
                    <li><Link to={contactData.instagram} target="_blank"><i className="fa fa-instagram" /></Link></li>
                    <li><Link to={contactData.linkdin} target="_blank"><i className="fa fa-linkedin" /></Link></li>
                    <li><Link to={contactData.youtube} target="_blank"><i className="fa fa-youtube" /></Link></li>
                    <li><Link to={`https://api.whatsapp.com/send?phone=${contactData.contectOne}`} target="_blank"><i className="fa fa-whatsapp" /></Link></li>
                    <li><Link to={`https://www.instagram.com/testprep_with_gatewayjaipur/`} target="_blank"><i className="fa fa-instagram" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="footer-middle ps-5">
                <div className="footer-menu">
                  <h4 className="footer-title">Quick Links</h4>
                  <ul className="list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/spoken-english">Spoken English</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/career">Career</Link></li>
                    <li><Link to="/contact">Contact us</Link></li>
                    <li><Link to="/gallary">Gallery</Link></li>
                  </ul>
                </div>
                <div className="footer-menu mt-4 test-pre-footer">
                  <h4 className="footer-title">Test Preparation</h4>
                  <ul className="list-unstyled">
                  {CourseData.map((course)=>(
                    <li><Link to={`/course/${course.pageName}`}>{course.pageName}</Link></li>
                  ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8">
              <div className="footer-right">
                <div className="footer-contact">
                  <h4 className="footer-title">Contact us</h4>
                  <ul className="list-unstyled">
                    <li><Link to="/">{contactData.officeAdress}</Link></li>
                    <li><span><i className="fa fa-whatsapp" /></span><Link to={`https://api.whatsapp.com/send?phone=${contactData.contectOne}`}>{contactData.contectOne}</Link>, <Link to={`tel:${contactData.contectTwo}`}>{contactData.contectTwo}, <Link to={`tel:${contactData.contectThree}`}>{contactData.contectThree}</Link></Link></li>
                    <li><span><i className="fa fa-envelope-o" /></span><Link to={`mailto:${contactData.email}`}>{contactData.email}</Link></li>
                  </ul>
                </div>
                <div className="footer-newsletter mt-4">
                  <h4 className="footer-title">Newsletter</h4>
                  <form>
                    <input type="email" name="email" placeholder="Enter your email" onChange={(e)=>setNewsEmail(e.target.value)} className="form-control" />
                    <button type="submit" onClick={(e)=>{handleUpdate2(e)}}>Subscribe</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom text-center py-4">
        <p>Copyrights © 2024 All Rights Reserved by Gateway Abroad.</p>
      </div>
    </footer>

    <div className="scroll_top">
      <Link to="javascript:void(0)" id="scroll-button" style={{display: 'block'}} onClick={()=>{window.scrollTo(0, 0)}}><i className="fa fa-angle-up" /></Link>
    </div>
    <div className="get-in-touch-sidebar">
      <button data-bs-toggle="modal" data-bs-target="#getintouchModel"><span className="content-red"><i className="fa fa-envelope-o me-2" /> Get in touch</span><span className="content-dark"><i className="fa fa-long-arrow-down" /></span></button>
    </div>
    <div className="modal right fade" id="getintouchModel" tabIndex={-1} aria-labelledby="getintouchModelLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="getintouchModelLabel">Get in touch</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="get-in-touch-form">
              <form>
                <div className="input-field">
                  <input type="text" name="name" className="form-control" placeholder="Name" onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="input-field">
                  <input type="email" name="email" className="form-control" placeholder="Email"  onChange={(e) => setEmail(e.target.value)} required/>
                </div>
                <div className="input-field">
                  <input type="text" name="phone" className="form-control" placeholder="Mobile No." onChange={(e) => setMobile(e.target.value)} required />
                </div>
                <div className="input-field">
                  <input type="text" name="city" className="form-control" placeholder="City"  onChange={(e) => setCity(e.target.value)} required />
                </div>
                <div className="input-field type-file-field">
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows={2} placeholder="Message" onChange={(e) => setMessage(e.target.value)} />
                </div>
                <button type="submit" onClick={(e)=>{handleUpdate(e)}}>SUBMIT</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>
  )
}

export default Footer;