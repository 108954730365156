import { React, useState, useEffect } from 'react'
import useAsync from '../../hooks/useAsync';

import PageServices from '../../services/PageServices';
import { Link, useParams ,useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from '../../Header';
import Footer from '../../Footer';
import Menu from '../../Menu';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddBlog() {
  const navigate = useNavigate();
  let { bId } = useParams();
  const [id,setId] = useState(bId);
  const [isEditing, setIsEditing] = useState(false);
  const [jobTitle, setJobtitle] = useState('');
  const [description, setDescription] = useState('');
  const [vacancy, setvacancy] = useState('');
  const [location, setlocation] = useState('');
 
  const [status, setstatus] = useState(true);
  const [file,setFile] = useState(null)

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    // You can store the file in state or perform further actions
  };

  useEffect(() => {
    
    if (id) {
      
      const fetchData = async () => {
        try {
          const response = await PageServices.getBlogDataById(id);
          if (response.status === 'success') {
            setJobtitle(response.data.blog.blogTitle || '');
            setDescription(response.data.blog.blogDescription || '');
            setvacancy(response.data.blog.createdBy || '');
            setlocation(response.data.blog.category || '');
            
            setstatus(response.data.job.Status || false);
             console.log(response)
          } else {
            console.log('something went wrong');
          }
        } catch (error) {
          // Handle error if the request fails
          console.error('Error fetching data:', error);
        }
      };

      // Call the asynchronous function
      fetchData();
      setIsEditing(true);
    } else {
      // If no id, it means we are adding a new job
      setIsEditing(false);
    }
  }, [id]);

  const handleUpdate = async (e) => {
    
    e.preventDefault();
    if (!jobTitle || !description || !location ) {
      alert('All fields are required');
      return;
    }

    const formData = new FormData();
        formData.append('blogTitle', jobTitle);
        formData.append('blogDescription', description);
        formData.append('createdBy', vacancy);
        formData.append('category', location); 
        formData.append('Status', status); 
        if(file)formData.append('file', file); 
    
    if(isEditing){
      try {
        // Make an API call to update the data
        const updatedData = await PageServices.updateBlogDataById(id,formData);
  
        if(updatedData.status === 'success'){
          alert('Blog Updated');
                  
        }else{
          alert('Something went wrong');
        }
  
      } catch (error) {
        console.error('Error updating data:', error);
        // Handle the error, e.g., show a message to the user
      }
    }else{
      try {
        // Make an API call to update the data
        const createJob = await PageServices.createBlog(formData);
  
        if(createJob.status === 'success'){
          setIsEditing(true);
          alert('Blog Created');
          navigate(`/admin/blog`);
                  
        }else{
          alert('Something went wrong');
        }
  
      } catch (error) {
        console.error('Error updating data:', error);
        // Handle the error, e.g., show a message to the user
      }
    }
  };
  
  console.log(description)

  return (
    
    <div className="content-wrapper">

      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{isEditing ? "Update" : "Add"} Blog</h1>
            </div>

          </div>
        </div>
      </section>


      <section className="content">


        <div className="card">
          <div className="card-header">
            <h3 className="card-title">{isEditing ? "Update" : "Add"} Blog</h3>

            <div className="card-tools">
              
            </div>
          </div>
          <div className="card-body">

            <div className="card-body">
              <form role="form">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Title</label>
                      <input type="text" className="form-control" value={jobTitle} onChange={(e) => setJobtitle(e.target.value)} placeholder="Title" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                  <div className="form-group">
                      <label>Select Course</label>
                      <select
                        className="form-control"
                        value={location} // Set the selected value
                        onChange={(e) => setlocation(e.target.value)}
                      ><option>Select Course</option>
                        <option value="GMAT">GMAT</option>
                        <option value="IELTS">IELTS</option>
                        <option value="TOEFL">TOEFL</option>
                        <option value="GRE">GRE</option>
                        <option value="PTE">PTE</option>
                        <option value="SAT">SAT</option>
                        <option value="ENGLISH">SPOKEN ENGLISH</option>
                      </select>
                    </div>
                  </div>

                </div>
                <div className="row">
                  
                  <div className="col-sm-6">
                    {/* textarea */}
                    <div className="form-group">
                      <label>Description</label>
                      <ReactQuill theme="snow" value={description} onChange={setDescription} />
                    </div>
                  </div>
       
                  <div className="col-sm-6">
                  <label>image</label>
            <div className="custom-file">
                     <label>image</label>
                      <input type="file" className="custom-file-input" id="customFile"  onChange={handleFileChange}/>
                      <label className="custom-file-label" for="customFile">{file?.name?file?.name:"choose File"}</label>
                    </div>
              </div> 

                </div>
                <div className="row">
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <label>Author</label>
                      <input type="text" value={vacancy} onChange={(e) => setvacancy(e.target.value)} className="form-control" placeholder="Author" />
                    </div>
                  </div> */}
                 
                  


                </div>
                {/* <div className="row">
                  <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch3"
                      checked={status} // Set the checked attribute
                      onChange={() => setstatus(!status)} // Toggle the status when the checkbox is clicked
                    />
                    <label className="custom-control-label" for="customSwitch3">Status</label>
                  </div>
                </div> */}


              </form>
            </div>



          </div>

          <div className="card-footer">
          <button type="submit" onClick={(e)=>{handleUpdate(e)}} className="btn btn-primary">Submit</button>
          </div>

        </div>


      </section>

    </div>
    
  )
}

export default AddBlog