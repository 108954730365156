import React, { Component,useState } from 'react'
import { Link } from 'react-router-dom'

export default class Menu extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      userName: ''
    };
  }

  componentDidMount() {
    // Retrieve the user's name from local storage
    const userName = localStorage.getItem('name');
    if (userName) {
      this.setState({ userName });
    }
  }
  

    render() {
      const { userName } = this.state;
        return (
          <div>
  <aside className="main-sidebar sidebar-dark-primary elevation-4">
    {/* Brand Logo */}
    
    {/* Sidebar */}
    <div className="sidebar">
      {/* Sidebar user panel (optional) */}
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
        </div>
        <div className="info">
          <Link to="#" className="d-block">{userName}</Link>
        </div>
      </div>
      {/* Sidebar Menu */}

      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}
          <li className="nav-item has-treeview menu-open">
            <Link to="/" className="nav-link active">
              <i className="nav-icon fa fa-tachometer-alt" />
              <p>
                <i className='fa fa-home me-2'></i>
                Visit Site
              </p>
            </Link>
            
          </li>      
      <li className="nav-header">PAGES</li>
          {/* <li className="nav-item">
            <Link to="/admin/home" className="nav-link">
              <i className="nav-icon fa fa-book" />
              <p>
                Home Page
              </p>
            </Link>
          </li> */}
          <li className="nav-item">
            <Link to="/admin/about" className="nav-link">
              <i className="nav-icon fa fa-info-circle" />
              <p>
                About Page
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/blog" className="nav-link">
              <i className="nav-icon fa fa-rss" />
              <p>
                Blog
              </p>
            </Link>
          </li>
          
          <li className="nav-item has-treeview">
            <Link to="#" className="nav-link">
              <i className="nav-icon fa fa-phone" />
              <p>
                Contact Page
                <i className="fa fa-angle-left right" />
                <span className="badge badge-info right">4</span>
              </p>
            </Link>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <Link to="/admin/contact" className="nav-link">
                  <i className="fa fa-circle nav-icon" />
                  <p>Contact Page</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/contact-form" className="nav-link">
                  <i className="fa fa-circle nav-icon" />
                  <p>Contact Form</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/register-form" className="nav-link">
                  <i className="fa fa-circle nav-icon" />
                  <p>Register Form</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/contact-detail" className="nav-link">
                  <i className="fa fa-circle nav-icon" />
                  <p>Contact Detail</p>
                </Link>
              </li>
             
            </ul>
          </li>
          <li className="nav-item has-treeview">
            <Link to="#" className="nav-link">
              <i className="nav-icon fa fa-briefcase" />
              <p>
                Career Page
                <i className="fa fa-angle-left right" />
                <span className="badge badge-info right">4</span>
              </p>
            </Link>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <Link to="/admin/career" className="nav-link">
                  <i className="fa fa-circle nav-icon" />
                  <p>Career Page</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/all-job" className="nav-link">
                  <i className="fa fa-circle nav-icon" />
                  <p>Job</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/resume" className="nav-link">
                  <i className="fa fa-circle nav-icon" />
                  <p>Resume</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/partner" className="nav-link">
                  <i className="fa fa-circle nav-icon" />
                  <p>Partner</p>
                </Link>
              </li>
             
            </ul>
          </li>
          

          <li className="nav-item">
            <Link to="/admin/courses" className="nav-link">
              <i className="nav-icon fa fa-graduation-cap" />
              <p>
                Courses
              </p>
            </Link>
          </li>
          
          <li className="nav-header">MODULE</li>

          <li className="nav-item">
            <Link to="/admin/testimonial" className="nav-link">
              <i className="nav-icon fa fa-quote-left" />
              <p>
                Testimonial
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/faq" className="nav-link">
              <i className="nav-icon fa fa-question-circle" />
              <p>
                FAQ
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/member" className="nav-link">
              <i className="nav-icon fa fa-user-circle-o" />
              <p>
                Member
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/students" className="nav-link">
              <i className="nav-icon fa fa-question-circle" />
              <p>
                Student
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/office" className="nav-link">
              <i className="nav-icon fa fa-question-circle" />
              <p>
                Office
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/newsletter" className="nav-link">
              <i className="nav-icon fa fa-question-circle" />
              <p>
                NewsLetter
              </p>
            </Link>
          </li>

          <li className="nav-item has-treeview">
            <Link to="#" className="nav-link">
              <i className="nav-icon fa fa-picture-o" />
              <p>
                Photo And Video
                <i className="fa fa-angle-left right" />
                <span className="badge badge-info right">2</span>
              </p>
            </Link>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <Link to="/admin/studentvideo" className="nav-link">
                  <i className="fa fa-circle nav-icon" />
                  <p>Student Video</p>
               </Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/gellary" className="nav-link">
                  <i className="fa fa-circle nav-icon" />
                  <p>Gallery</p>
                </Link>
              </li>
              
             
            </ul>
          </li>
          
         
         
        </ul>
      </nav>
      {/* /.sidebar-menu */}
    </div>
    {/* /.sidebar */}
  </aside>
</div>

        )
    }
}
